
import {Component, Prop, VModel, Vue} from 'vue-property-decorator';

@Component
export default class CheckboxLink extends Vue {
  @VModel({type: Boolean, required: true}) private checked: boolean | undefined
  @Prop({type: String, required: true}) private fullText: string | undefined
  @Prop({type: String, required: true}) private link: string | undefined
  @Prop({type: String, required: true}) private linkLabel: string | undefined
  @Prop({type: String, required: true}) private label: string | undefined

  private before: string | null = null
  private after: string | null = null

  public mounted(): void {
    if (this.fullText) {
      const split = this.fullText!.split('{link}')
      this.before = split[0]
      this.after = split[1]
    }
  }
}
