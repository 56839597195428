
import {Component, Prop, Vue} from 'vue-property-decorator';
import {AxiosRestApplicationClient, FrontInfoResponse} from '../services/client';
import CheckboxLink from "./components/CheckboxLink.vue";

@Component({
  components: {CheckboxLink},
  head: {
    title() {
      return {
        inner: 'Csc',
        complement: 'Consent'
      };
    },
    link() {
      return [
        {rel: 'stylesheet', h: 'assets/specific.css', id: 'specific'},
        {rel: 'shortcut icon', h: 'assets/favicon.ico', id: 'favicon'}

      ];
    }
  },
})


export default class GetConsent extends Vue {

  @Prop(Number)
  public numPage?: number;

  public frontInfoResponse: FrontInfoResponse = <FrontInfoResponse>{};

  public gcs = false;
  public personalData = false;
  public personalDataDialog = false;
  public error = false;
  public warn = '';

  public translationsReady = false;
  public frontPropertiesReady = false;

  public user = {};
  public encodedData = '';
  public infoValidated = false;

  $refs!: {
    form: HTMLFormElement
  };

  private httpClient = new AxiosRestApplicationClient('/');

  public created(): void {

    this.$languages.changeLanguage(<string>this.$route.query.lang, <string>this.$route.query.tenant_id).then((lang: string) => {
      this.$vuetify.lang.current = lang;
      console.log('Using language:', lang);
      this.warn = <string>Vue.i18n.translate('consent_error');
      this.translationsReady = true;
    });

    let that = this;

    this.user = {
      lastName: this.$route.query.last_name,
      firstName: this.$route.query.first_name,
    };

    this.encodedData = <string>this.$route.query.encoded_data;

    //get cgs link
    this.httpClient.info$GET$csc_v0_front({
      lang: <string>this.$route.query.lang,
      tenant: <string>this.$route.query.tenant_id
    }).then(function (response) {
      that.frontInfoResponse = response.data;
      that.frontPropertiesReady = true;

    });

  }

  public submit(): void {
    if (this.$refs.form.validate()) {
      this.error = false;
      if (this.numPage == 1) {
        var newUser = this.$route.query.new_user ? this.$route.query.new_user : 'false';
        var url = '/csc/v0/oauth2/consent?' +
            'response_type=' + this.$route.query.response_type +
            '&client_id=' + this.$route.query.client_id +
            '&redirect_uri=' + this.$route.query.redirect_uri +
            '&lang=' + this.$route.query.lang +
            '&state=' + this.$route.query.state +
            '&consent_id=' + this.$route.query.consent_id +
            '&consent_cgs=' + this.gcs +
            '&consent_personal_data=' + this.personalData +
            '&new_user=' + newUser;
        if (this.$route.query.account_token) {
          url += '&account_token=' + this.$route.query.account_token;
        }
        location.replace(url);
      } else {
        location.replace('/csc/v0/oauth2/validate-info/' + this.encodedData +
            '?validate_info=' + this.infoValidated);
      }
    } else {
      this.error = true;
    }
  }

  public cancel(): void {
    if (this.numPage == 1) {
      location.replace('/csc/v0/oauth2/refuse?redirect_uri=' + this.$route.query.redirect_uri);
    } else {
      let decodedStr: string[] = atob(this.encodedData).split('|');
      location.replace('/csc/v0/oauth2/refuse?redirect_uri=' + decodedStr[0] + '&consent_id=' + decodedStr[1]);
    }
  }
}
